'use strict'

BREAK_POINT = 768
VIEW_WIDTH = 1300

do ->
	w = document.documentElement.clientWidth
	if BREAK_POINT <= w
		vp = document.querySelector '[name="viewport"]'
		vp.content = "width=device-width, initial-scale=1" if vp

$ ->

	$body = $('body')

	$('a, area').bcScrollTo()
	# $('a[href], button').bcWink();

	##
	# トップスライドショー
	#
	##
	$slideshow = $('[data-psyborg="slideshow"]')
	if $slideshow.length
		$slideshow.bcImageLoaded ->
			$slideshow.psycle
				transition: 'fade'
				delay: 7000
				duration: 700
				resizable: on
			$slideshow.find('li a, li span').bcBackground outer: on
			p = $slideshow.data 'psycle'
			p.trigger $.PsycleEvent.RESIZE_END
			$m = p.marker()
			$m.addClass 'billboard__marker'
			$m.appendTo $slideshow


	$heroBg = $('.billboard__panels li img')
	$(window).scroll ->
		if $(window).scrollTop() > 50
			$heroBg.css('top', 0 + $(window).scrollTop() * 0.2 + 'px')
		else
			$heroBg.css('top', 0)

	##
	# ラインナップ 横スク
	#
	##
	$('[data-psyborg="scrollslide"]').bcImageLoaded ->
		$crsl = $ @
		$crsl.psycle
			transition: 'slide'
			centerMode: true
			auto: true
			repeat: 'loop'
			duration: 900
			delay: 2000
			container: '>*:eq(0)'
			panels: '>*'
			swipeable: true
			draggable: true
		p = $crsl.data 'psycle'
		id = $crsl.attr('data-id');
		if not /[a-z-_][a-z0-9-_]*/ig.test id
			window.console?.warn? '"data-id" attribute value is invalid.'
			return
		$ctrl = $('[data-psyborg="scrollslide-ctrl"][data-control="' + id + '"]')
		if $ctrl.length
			p.ctrl $ctrl,
				prev: '[data-psyborg="scrollslide-ctrl-prev"]'
				next: '[data-psyborg="scrollslide-ctrl-next"]'
			$crsl.find('.link-box__thumb, .link-line__thumb').bcImageLoaded ->
				$(@).bcBackground outer: on
		return

	##
	# カルーセル
	# ※呼び出しは背景画像化が完了してから
	##
	$('[data-psyborg="carousel"]').bcImageLoaded ->
		$crsl = $ @
		$crsl.psycle
			transition: 'slide'
			repeat: 'loop'
			auto: off
			resizable: on
			draggable: on
			swipeable: on
		p = $crsl.data 'psycle'
		id = $crsl.attr('data-id');
		if not /[a-z-_][a-z0-9-_]*/ig.test id
			window.console?.warn? '"data-id" attribute value is invalid.'
			return
		$ctrl = $('[data-psyborg="carousel-ctrl"][data-control="' + id + '"]')
		if $ctrl.length
			p.ctrl $ctrl,
				prev: '[data-psyborg="carousel-ctrl-prev"]'
				next: '[data-psyborg="carousel-ctrl-next"]'
			$crsl.find('.link-box__thumb, .link-line__thumb').bcImageLoaded ->
				$(@).bcBackground outer: on
		$marker = $('[data-psyborg="carousel-marker"][data-for="' + id + '"]')
		if $marker.length
			p.marker().appendTo $marker
		return

	##
	# メガドロップななめのやつ
	#
	##
	$.fn._mega = (opt) ->

		SVG_NS = 'http://www.w3.org/2000/svg'

		isMousedown = false

		timer = 0

		config = $.extend
			delay: 200
			trigger: '>a'
			subMenuBase: '>ul'
			subMenuArea: '>ul >*:first-child'
			vector: 'to bottom'
		,
			opt


		@each ->
			$i = $(@)
			$p = $i.find(config.subMenuArea)
			if $p.find('li').length is 0
				$p.remove()
				$i.off 'mouseenter mouseleave'

		createSVGElement = (tag) ->
			return document.createElementNS SVG_NS, tag

		hitArea = createSVGElement 'svg'
		hitAreaPolygon = createSVGElement 'polygon'
		hitAreaPolygon.setAttribute 'fill', 'transparent'
		# hitAreaPolygon.setAttribute 'fill', 'rgba(255, 0, 0, 0.4)'
		hitAreaPolygon.setAttribute 'style', 'cursor: pointer'
		hitArea.appendChild hitAreaPolygon

		getRect = ($el) ->
			$el[0].getBoundingClientRect()

		enter = ->
			if isMousedown
				remove()
				return
			$this = $ @
			$target = $this.find config.trigger
			unless $target.length then return
			$subMenuBase = $this.find config.subMenuBase
			subMenuBase = $subMenuBase[0]
			unless $subMenuBase.length then return
			$subMenuArea = $this.find config.subMenuArea
			unless $subMenuArea.length then return
			targetRect = getRect $target
			subMenuBaseRect = getRect $subMenuBase
			subMenuAreaRect = getRect $subMenuArea
			switch config.vector
				when 'to bottom'
					points = [
						[targetRect.left, 0]
						[targetRect.left + targetRect.width, 0]
						[subMenuAreaRect.left + subMenuAreaRect.width, targetRect.height]
						[subMenuAreaRect.left, targetRect.height]
					]
					hitArea.setAttribute 'width', subMenuBaseRect.width
					hitArea.setAttribute 'height', targetRect.height
					hitArea.setAttribute 'style', "position: absolute; top: #{targetRect.height * -1}px; left: 0;"
				when 'to right'
					points = [
						[0, targetRect.top - subMenuAreaRect.top]
						[targetRect.width, 0]
						[targetRect.width, subMenuAreaRect.height]
						[0, targetRect.top - subMenuAreaRect.top + targetRect.height]
					]
					hitArea.setAttribute 'width', targetRect.width
					hitArea.setAttribute 'height', subMenuBaseRect.height
					hitArea.setAttribute 'style', "position: absolute; top: 0px; left: #{targetRect.width * -1}px;"
			hitAreaPolygon.setAttribute 'points', points.join()
			subMenuBase.appendChild hitArea
			countdown()
			return

		countdown = ->
			clearTimeout timer
			timer = setTimeout remove, config.delay
			return

		remove = ->
			hitArea.parentNode?.removeChild hitArea
			return

		mousedown = ->
			isMousedown = true

		@on 'mouseenter', enter
		@on 'mousedown', mousedown

		hitAreaPolygon.addEventListener 'mousemove', countdown, false
		hitAreaPolygon.addEventListener 'mouseout', remove, false

		return @

	##
	# メガドロップ
	#
	##
	$('.nav-global__item').on 'mouseenter', ->
		$mdd = $(@).find('.mega-dropdown')
		if $mdd.length
			$body.addClass 'is-nav-hovered'
		else
			$body.removeClass 'is-nav-hovered'
		return
	.on 'mouseleave', ->
		$body.removeClass 'is-nav-hovered'
		return
	$('.nav-global__item')._mega
		subMenuBase: '.mega-dropdown'
		subMenuArea: '.mega-dropdown__inner'
		vector: 'to bottom'
	$('.nav-global__item [data-sitemap="page-category"]')._mega
		subMenuArea: '>ul'
		vector: 'to right'

	##
	# スマホグロナビ
	#
	##
	$('[data-toggle-for]').each ->
		DUR = 500
		$toggle = $ @
		id = $toggle.data 'toggleFor'
		statusKeyName = "data-toggle-status-#{id}"
		if id
			try
				$target = $ "##{id}"
				$toggle.on 'click', ->
					if 'open' is $body.attr statusKeyName
						$target.stop(on, on).slideUp DUR, ->
							$body.attr statusKeyName, 'close'
					else
						$target.stop(on, on).slideDown DUR
						$body.attr statusKeyName, 'open'
					return false
			catch err
				# err

	##
	# 高さ揃え
	#
	##
	alignOption = {}
	alignOption.defaults = 3
	alignOption[BREAK_POINT] = 2
	$('.content-group--page-post .link-box .post-info__title').bcBoxAlignHeight alignOption
	$('.content-group--page-post .link-box .post-info__description').bcBoxAlignHeight alignOption
	$('.content-group--page-post .link-box .post-info').bcBoxAlignHeight alignOption

	##
	# 背景画像化
	#
	##
	$('.link-box__thumb, .link-line__thumb').filter ->
		!$(@).closest('[data-psyborg="carousel"]').length
	.bcImageLoaded ->
		$(@).bcBackground outer: on

	##
	# マップ
	#
	##
	$('[data-baser="Maps"][data-lat][data-lng]').bcMaps()

	##
	# フッターサイトマップ
	#
	##
	$footerSiteMap = $('.footer__sitemap.nav-sitemap')
	footerSiteMapCols = +$footerSiteMap.data('listCols');
	if footerSiteMapCols
		$footerSiteMap.bcSplitList footerSiteMapCols, splitChildren: no


	##
	# ブロックエフェクト
	#
	##
	if $.fn.sight
		$blocks = $('[data-bgb]').sight()
		$blocks.on 'sightenter sightshow', ->
			$(@).addClass 'js-sight'
